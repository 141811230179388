html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
* {
    outline: none;
}
body {
    background-image:url("../images/background.jpg");
    font:18px/22px Titillium Web,Arial,sans-serif;
    color:#514d4b;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
table {
    margin-top:0;
    margin-bottom:20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height:34px;
    font-weight:bold;
}
h1 {
    font-size:30px;
}
h2 {
    font-size:24px;
    font-weight: 600;
}
h3 {
    font-size:18px;
}
h4,
h5,
h6 {
    font-size:17px;
}
ul {
    padding-left:30px;
    list-style-type:square;
}
a,
.ce_accordion .toggler {
    text-decoration:underline;
    color:#514d4b;
    cursor: pointer;
}
a:hover {
    color:#ef6395;
}
table {
    width:100%;
    border-collapse:collapse;
    border-spacing:0;
}
table th,
table td {
    padding:4px 6px;
}
table thead th {
    border-bottom:2px solid #827e77;
}
table tbody th,
table tbody td {
    vertical-align:middle;
    border-bottom:1px dashed #827e77;
}
table tbody tr:hover th,
table tbody tr:hover td {
    background-color:#ffea81;
}
table caption {
    margin-bottom:10px;
    text-align:center;
    font-weight:bold;
    font-style:italic;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.block {
    overflow:visible;
}
#wrapper {
    width:auto;
    min-width:1140px;
    margin-bottom:30px;
}
#header .inside,
#container,
#footer .inside {
    width:1140px;
    margin-right:auto;
    margin-left:auto;
}
#header .inside,
#footer .inside,
#left .inside,
#right .inside {
    padding-right:10px;
    padding-left:10px;
}
#left .inside .mod_article *[class*="ce_"],
#left .inside .mod_article *[class*="mod_"],
#right .inside .mod_article *[class*="ce_"],
#right .inside .mod_article *[class*="mod_"] {
    margin-right:0;
    margin-left:0;
}
.mod_article *[class*="layout_"]>*,
.mod_article *[class*="event_"]>* {
    margin-right:0;
    margin-left:0;
}
.logo-image-popup {
    width:auto;
    max-width:500px;
    position:relative;
    margin:20px auto;
    padding:30px;
    text-align:center;
    background-color:#fff;
}
#header {
    margin-bottom:10px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d4cebd+0,ffffff+100&amp;0.65+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(212,206,189,0.65) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(212,206,189,0.65)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(212,206,189,0.65) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(212,206,189,0.65) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(212,206,189,0.65) 0%,rgba(255,255,255,0) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(212,206,189,0.65) 0%,rgba(255,255,255,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6d4cebd', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}
#header .inside:after {
    content: "";
    display: table;
    clear: both;
}
#header .left_box {
    float:left;
}
#header .right_box {
    float:right;
    padding-top:80px;
}
#header .left_box:after,
#header .right_box:after {
    content: "";
    display: table;
    clear: both;
}
#header .logo {
    width:245px;
    float:left;
    margin-right:20px;
    text-align:center;
}
#header .main_menu {
    float:left;
}
#header .main_menu ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
#header .main_menu ul li {
    float:left;
    margin:0 10px 0 0;
    padding:0;
}
#header .main_menu ul li.last {
    margin-right:0;
}
#header .main_menu a,
#header .main_menu strong {
    display:block;
    padding:86px 20px 38px;
    font-size:18px;
    line-height:22px;
    text-decoration:none;
    color:#514d4b;
    text-transform:uppercase;
}
#header .main_menu a:hover {
    background-color:#ffdd21;
}
#header .main_menu a.trail,
#header .main_menu strong {
    background-color:#ffdd21;
    font-weight:bold;
}
#header .search_box {
    width:170px;
    height:32px;
    position:relative;
    float:left;
    margin-right:20px;
    padding-top:1px;
    padding-right:40px;
    padding-left:4px;
    background-color:#fff8e3;
    border-top:1px solid #bcb7a7;
    border-radius:8px;
}
#header .search_box input {
    width:100%;
    height:28px;
    display:block;
    margin:0;
    padding:0;
    font-size:16px;
    line-height:28px;
    color:#278aad;
    border: none;
    background: none;
    -webkit-appearance: none;
}
#header .search_box button {
    width:30px;
    right:6px;
    top:4px;
    position:absolute;
    margin:0;
    padding:0;
    text-align:center;
    font-size:22px;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearance: none;
}
#header .facebook_link,
#header .instagram_link {
    float:left;
    margin-top:5px;
    font-size:24px;
    text-decoration:none;
}
#header .instagram_link {
    margin-left:10px;
}
#container a[href^="http"] {
    top:0;
    position:relative;
    display:inline-block;
    padding-left:23px;
}
#container :not(.image_container) a[href^=http]:before {
    left:0;
    top:0;
    position:absolute;
    content: "\e902";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#container .image_container > a[href^="http"] {
    position: static;
    display: inline;
    padding-left: 0;
}
#container .image_container > a[href^="http"]:before {
    display: none;
}
.mod_search .formbody {
    width:100%;
    height:36px;
    position:relative;
    float:left;
    margin-bottom:10px;
    padding-top:1px;
    padding-right:40px;
    padding-left:4px;
    background-color:#fff8e3;
    border:1px solid #bcb7a7;
    border-radius:8px;
}
.mod_search .formbody input {
    width:100%;
    height:32px;
    display:block;
    margin:0;
    padding:0;
    font-size:18px;
    line-height:32px;
    color:#278aad;
    border: none;
    background: none;
}
.mod_search .formbody button {
    width:30px;
    right:6px;
    top:6px;
    position:absolute;
    margin:0;
    padding:0;
    text-align:center;
    font-size:22px;
    border: none;
    background: none;
    cursor: pointer;
}
.mod_search > div {
    margin-bottom:10px;
    padding-bottom:10px;
    border-bottom:1px dashed #827e77;
}
.mod_search > div.last {
    border-bottom:0;
}
.mod_search h3,
.mod_search p {
    margin-bottom:10px;
}
.mod_search .header {
    color:#827e77;
}
.mod_search .relevance {
    margin-left:4px;
    color:#827e77;
}
.mod_search .highlight {
    background-color:#fd2;
}
.mod_search .url {
    color:#278aad;
}
.ce_hyperlink {
    margin-bottom:10px;
}
.ce_download {
    margin-bottom:5px;
}
.ce_downloads {
    margin-bottom:20px;
}
.ce_tvlyss_rankings a {
    text-decoration:none;
}
.ce_download a:hover,
.ce_downloads a:hover,
.ce_tvlyss_rankings a:hover {
    text-decoration:underline;
}
.ce_download img,
.ce_downloads img,
.ce_tvlyss_rankings img {
    top:2px;
    position:relative;
}
.ce_download .size,
.ce_downloads .size,
.ce_tvlyss_rankings .size {
    margin-left:2px;
    font-size:14px;
    color:#827e77;
}
.ce_download a:hover .size,
.ce_downloads a:hover .size,
.ce_tvlyss_rankings a:hover .size {
    color:#ef6395;
}
.ce_downloads ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
.ce_downloads ul li {
    margin:0 0 5px;
    padding:0;
}
.ce_downloads li .image_container {
    margin-top: 2rem;
    margin-bottom: .5rem;
}
.ce_downloads li:first-child .image_container {
    margin-top: 0;
}
a[href*=".pdf"] {
    top:0;
    position:relative;
    display:inline-block;
    padding-left:23px;
}
a[href*=".pdf"]:before {
    left:0;
    top:0;
    position:absolute;
    content: "\e903";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ce_tvlyss_focus ul {
    padding-left:10px;
    border-left:8px solid #ffdd21;
    list-style-type:none;
}
.ce_tvlyss_focus ul li {
    margin-bottom:10px;
}
.ce_tvlyss_focus a {
    text-decoration:underline;
}
.ce_tvlyss_rankings ul {
    margin-top:0;
    margin-right:0;
    margin-left:0;
    padding:0;
    list-style-type:none;
}
.ce_tvlyss_rankings ul li {
    margin:0 0 5px;
    padding:0;
}
.person_list:after {
    content: "";
    display: table;
    clear: both;
}
.person_list .ce_tvlyss_member {
    width:50%;
    min-height:200px;
    float:left;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.person_list .ce_tvlyss_member.last {
    border-bottom:1px dashed #827e77;
}
.ce_gallery img,
.ce_facebook_album img {
    display:block;
}
.ce_gallery ul,
.ce_facebook_album ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
.ce_gallery ul li,
.ce_facebook_album ul li {
    float:left;
    margin:0 10px 10px 0;
    padding:0;
}
.ce_gallery ul li.col_last,
.ce_facebook_album ul li.col_last {
    margin-right:0;
}
ce_facebook_album .info {
    clear: both;
}
.ce_tvlyss_member {
    margin-bottom:20px;
    padding-bottom:10px;
    border-bottom:1px dashed #827e77;
}
.ce_tvlyss_member .member_box.has_image:after {
    content: "";
    display: table;
    clear: both;
}
.ce_tvlyss_member .member_box.has_image .text_box {
    margin-left:160px;
}
.ce_tvlyss_member .image_box {
    width:140px;
    float:left;
}
.ce_tvlyss_member .image_box img {
    border-radius:50%;
}
.ce_tvlyss_member.last {
    border-bottom:0;
}
.ce_tvlyss_member .organization {
    display:none;
}
.ce_tvlyss_member .jobtitle {
    font-weight:bold;
}
.ce_tvlyss_member .email {
    overflow: hidden;
    text-overflow: ellipsis;
}
.ce_tvlyss_member .email a,
.ce_tvlyss_member .telephone {
    color:#278aad;
}
.ce_tvlyss_member .note {
    padding:10px;
    background-color:#ffdd21;
}
.ce_text li {
    line-height: 1.6667;
}
.separator-after {
    margin-top:20px;
    padding-bottom:10px;
    border-bottom:1px dashed #827e77;
}
.pagination {
    margin-top:10px;
    padding:10px;
    border:1px dashed #827e77;
}
.pagination:after {
    content: "";
    display: table;
    clear: both;
}
.pagination p {
    float:left;
    margin:0;
}
.pagination ul {
    float:right;
    margin:0;
    padding:0;
    list-style-type:none;
}
.pagination ul li {
    float:left;
    margin:0 4px;
    padding:0;
}
.pagination ul li:last-child {
    margin-right:0;
}
.pagination .current {
    font-weight:bold;
    color:#278aad;
}
.highlight-important {
    background-color:#ffdd21;
}
.event-cancelled-badge {
    display: block;
    margin-top: 5px;
    text-align: center;
    font-size: 7px;
    line-height: 1;
    font-weight: 700;
    padding: 5px;
    text-transform: uppercase;
    color: #d23;
    border: 2px solid #d23;
}
.mod_article .mod_eventreader {
    margin-right:10px;
    margin-left:10px;
}
.mod_eventreader *[class*="ce_"],
.mod_tvlyss_agenda *[class*="ce_"] {
    margin-right:0;
    margin-left:0;
}
.mod_eventreader .back a {
    color:#278aad;
}
.mod_eventreader .back a:before {
    content: "<< ";
}
.mod_eventreader .layout_full .top_box {
    margin-bottom:30px;
}
.mod_eventreader .layout_full .top_box:after {
    content: "";
    display: table;
    clear: both;
}
.mod_eventreader .layout_full .top_box_inner {
    display: flex;
    justify-content: space-between;
}
.mod_eventreader .layout_full h1 {
    margin-top:12px;
    margin-right:20px;
    margin-bottom:0;
}
.mod_eventreader .layout_full .badges_box {
    margin-bottom: 30px;
}
.mod_eventreader .layout_full .badges_box ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
.mod_eventreader .layout_full .badges_box:after {
    content: "";
    display: table;
    clear: both;
}
.mod_eventreader .layout_full .badges_box ul li {
    float:left;
    margin-right:10px;
}
.mod_eventreader .layout_full .badges_box .badge {
    display: flex;
    align-items: flex-end;
    width:38px;
    height: 64px;
}
.mod_eventreader .layout_full .date {
    width:48px;
    height:64px;
    position:static;
    background-color:#fff;
    float: right !important;
    margin-top: 12px;
    margin-right: 196px !important;
}
.mod_eventlist .layout_teaser:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom:1px dashed #827e77;
}
.mod_eventlist .layout_teaser h3 {
    margin: 0;
    line-height: 1.2;
}
.mod_eventlist .layout_teaser .more {
    margin-top: 10px;
}
.mod_eventlist .layout_teaser .date {
    width:48px;
    min-width:48px;
    margin-right: 20px;
    background-color:#fff;
}
.mod_eventlist .layout_teaser .event_header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.mod_eventlist .layout_teaser .text_box {
    font-size:1rem;
}
.mod_tvlyss_agenda .layout_list {
    position:relative;
    margin-bottom:20px;
    padding-bottom:20px;
    border-bottom:1px dashed #827e77;
}
.mod_tvlyss_agenda .layout_list .inner {
    padding:10px 20px;
    min-height: 79px;
}
.mod_tvlyss_agenda .layout_list.featured .inner {
    background-color:rgba(255,221,34,.75);
}
.mod_tvlyss_agenda .layout_list .inner:after {
    content: "";
    display: table;
    clear: both;
}
.mod_tvlyss_agenda .layout_list .image_container {
    width:285px;
    float:left;
    margin-right:20px;
}
.mod_tvlyss_agenda .layout_list .image_container + .text_box {
    margin-top:0;
    margin-left:305px;
}
.mod_tvlyss_agenda .layout_list .date {
    width:48px;
    height:64px;
    left:0;
    top:0;
    position:absolute;
    background-color:#fff;
}
.mod_tvlyss_agenda .layout_list .date .month,
.mod_eventreader .layout_full .date .month,
.mod_eventlist .layout_teaser .date .month {
    height:24px;
    display:block;
    text-align:center;
    background-color:#ef6395;
    font-size:18px;
    line-height:24px;
    color:#fff8e3;
    text-transform:uppercase;
}
.mod_tvlyss_agenda .layout_list .date .day,
.mod_eventreader .layout_full .date .day,
.mod_eventlist .layout_teaser .date .day {
    height:40px;
    display:block;
    text-align:center;
    font-size:31px;
    line-height:40px;
    font-weight:bold;
}
.mod_tvlyss_agenda .layout_list .date .year,
.mod_eventreader .layout_full .date .year,
.mod_eventlist .layout_teaser .date .year {
    display:block;
    text-align:center;
    background-color:#514d4b;
    font-size:11px;
    line-height:15px;
    color:#fff8e3;
}
.mod_tvlyss_agenda .layout_list .categories {
    float:left;
    margin:0;
    padding:0;
    list-style-type:none;
    display: flex;
    align-items: center;
}
.mod_tvlyss_agenda .layout_list .category {
    display: flex;
    width:30px;
    height:40px;
    margin-right:10px;
}
.mod_eventreader .layout_full .info_box {
    padding-top:10px;
}
.mod_eventreader .layout_full .info_box .value {
    font-weight:bold;
}
.mod_eventreader .text_container:after {
    content: "";
    display: table;
    clear: both;
}
.mod_tvlyss_agenda .layout_list .text_box {
    margin-top:-10px;
    margin-left:48px;
}
.mod_tvlyss_agenda .layout_list .text_box h3 {
    margin-bottom:14px;
    line-height:24px;
}
.mod_tvlyss_agenda .layout_list .text_box h3 a {
    text-decoration:none;
}
.mod_tvlyss_agenda .layout_list .text_box h3 a:hover {
    text-decoration:underline;
}
.mod_tvlyss_agenda .layout_list .text_box p {
    margin-bottom:0;
}
.mod_tvlyss_agenda .layout_list .text_box .ce_text {
    margin-right:0;
    margin-bottom:10px;
    margin-left:0;
    font-size:14px;
    line-height:18px;
}
.mod_tvlyss_agenda .layout_list .text_box .more {
    float:right;
    margin-top:10px;
    margin-bottom:0;
    font-size:14px;
    line-height:18px;
    font-weight:bold;
}
.mod_tvlyss_agenda .layout_list .text_box .more a {
    text-decoration:none;
}
.mod_tvlyss_agenda .layout_list.event-cancelled h3 a,
.mod_tvlyss_agenda .layout_list.event-cancelled .ce_text {
    text-decoration: line-through;
}
.mod_eventreader .ce_tvlyss_event_start.type_main {
    width:calc(100% - 265px);
    float:left;
    margin-right:10px;
    margin-left:0;
}
.mod_eventreader .ce_tvlyss_event_start.type_right {
    width:255px;
    float:right;
    margin-right:0;
    margin-left:0;
    padding-right:0;
    padding-left:10px;
}
.mod_eventreader .event-cancelled .datetime {
    text-decoration: line-through;
}
.mod_tvlyss_agenda .formbody {
    margin-bottom:30px;
}
.mod_tvlyss_agenda .formbody_group:not(:last-child) {
    margin-bottom:10px;
}
.mod_tvlyss_agenda .formbody_group .widget {
    margin-bottom: 20px;
}
.mod_tvlyss_agenda .formbody_group .widget span.mandatory {
    display: none;
}
.mod_tvlyss_agenda .formbody_group .widget-select select {
    padding-right: 30px;
}
.mod_tvlyss_agenda .formbody_group .checkbox_container label,
.mod_tvlyss_agenda .formbody_group .radio_container label {
    display: inline;
    cursor: pointer;
}
.mod_tvlyss_agenda .formbody_group .checkbox_container input,
.mod_tvlyss_agenda .formbody_group .radio_container input {
    cursor: pointer;
}
.mod_tvlyss_agenda {
    margin-top: 50px;
}
.mod_tvlyss_agenda .agenda_container {
    display: flex;
}
.mod_tvlyss_agenda .agenda_container .agenda_form {
    min-width: 255px;
    width: 255px;
    padding-right: 10px;
    font-size: 1rem;
}
.mod_tvlyss_agenda .agenda_container .agenda_events {
    width: calc(100% - 255px);
    padding-left: 10px;
}
.mod_tvlyss_agenda .agenda_container button.submit {
    text-align:center;
    cursor: pointer;
    height: 35px;
}
.mod_tvlyss_agenda .agenda_container.ajax_loading button.submit span {
    display: none;
}
.mod_tvlyss_agenda .agenda_container.ajax_loading button.submit:before {
    width:16px;
    height:16px;
    display:inline-block;
    background-image:url("../images/ajax-loader-small.gif");
    background-repeat:no-repeat;
    content: "";
}
.mod_tvlyss_agenda .agenda_events {
    position:relative;
    padding-bottom:100px;
}
.mod_tvlyss_agenda .agenda_events.ajax_loading {
    min-height:1000px;
}
.mod_tvlyss_agenda .agenda_events.scroll_loading_terminated {
    padding-bottom:0;
}
.mod_tvlyss_agenda .agenda_events.ajax_loading:after {
    width:66px;
    height:66px;
    bottom:0;
    left:50%;
    position:absolute;
    margin-bottom:17px;
    margin-left:-33px;
    background-image:url("../images/ajax-loader.gif");
    content: "";
}
.mod_tvlyss_agenda .agenda_events .loading_box {
    padding:10px 25px;
    border:1px dashed #827e77;
}
.mod_tvlyss_agenda .agenda_events .loading_box:after {
    content: "";
    display: table;
    clear: both;
}
.mod_tvlyss_agenda .agenda_events .loading_box .text {
    float:left;
}
.mod_tvlyss_agenda .agenda_events .loading_box .info {
    float:right;
}
.mod_tvlyss_agenda .agenda_form .submit_container {
    text-align:center;
}
.ce_rsce_sponsors {
    padding-top: 20px;
}
.ce_rsce_sponsors h2 {
    text-align: center;
    font-size: 30px;
    border-top: 1px solid #827e77;
    border-bottom: 1px solid #827e77;
    margin-bottom: 0;
    padding: 20px 0;
}
.sponsors {
    margin-bottom: 60px;
    border-bottom: 2px dashed #827e77;
}
.sponsors * {
    box-sizing: border-box;
}
.sponsor {
    display: flex;
    padding: 20px 0;
}
.sponsor__name {
    margin-bottom: 5px;
    font-weight: bold;
}
.sponsor__website {
    margin-top: 5px;
}
.sponsor__address {
    font-size: 0.8em;
}
.sponsor__address-headline {
    font-weight: bold;
}
.sponsor__address-content {
    margin-bottom: 0;
}
.sponsors--platinum {
    background: rgba(255,221,34,.75);
    padding: 0 20px;
}
.sponsor--platinum:not(:last-child),
.sponsor--gold:not(:last-child) {
    border-bottom: 1px dashed #827e77;
}
.sponsor--platinum .sponsor__logo,
.sponsor--gold .sponsor__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    max-width: 25%;
    flex: 0 0 25%;
}
.sponsor--platinum .sponsor__content,
.sponsor--gold .sponsor__content {
    display: flex;
    max-width: 75%;
    flex: 0 0 75%;
}
.sponsor--platinum .sponsor__address,
.sponsor--gold .sponsor__address {
    min-width: 30%;
    margin-left: 10px;
}
.sponsors--silver,
.sponsors--bronze {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
}
.sponsor--silver,
.sponsor--bronze {
    width: 33%;
    margin-bottom: 20px;
    padding: 20px;
    flex: 0 0 33%;
    flex-direction: column;
    text-align: center;
}
.sponsor--silver .sponsor__logo,
.sponsor--bronze .sponsor__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    height: 100px;
}
.sponsor--silver .sponsor__details,
.sponsor--bronze .sponsor__details {
    display: flex;
    flex-direction: column;
}
.sponsor--silver .sponsor__name,
.sponsor--bronze .sponsor__name {
    order: 0;
}
.sponsor--silver .sponsor__description,
.sponsor--bronze .sponsor__description {
    text-align: justify;
    font-size: 0.8em;
    order: 2;
}
.sponsor--silver .sponsor__website,
.sponsor--bronze .sponsor__website {
    order: 1;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 0.8em;
}
.sponsor--silver .sponsor__address,
.sponsor--bronze .sponsor__address {
    margin-top: 10px;
    padding: 5px;
    border: 1px dashed #bdb8a6;
}
.timeline {
    margin-left: 10px;
    border-left: 3px solid #333333;
    background: rgba(255, 255, 255, 0.03);
    position: relative;
    padding: 50px;
    list-style: none;
    text-align: left;
}
.timeline::before {
    width: 15px;
    height: 5px;
    display: block;
    position: absolute;
    left: -9px;
    top: 0;
    background: #333;
    content: '';
}
.timeline::after {
    width: 15px;
    height: 5px;
    display: block;
    position: absolute;
    left: -9px;
    bottom: 0;
    background: #333;
    content: '';
}
.timeline__item {
    border-bottom:1px dashed #827e77;
    padding-bottom: 25px;
    padding-right: 25px;
    margin-bottom: 50px;
    position: relative;
}
.timeline__item-name {
    margin-bottom: .5em;
    font-weight: bold;
}
.timeline__item:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}
.timeline__item::after {
    position: absolute;
    display: block;
    box-shadow: 0 0 0 3px #333333;
    left: -56.85px;
    background: #ffdd22;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
    top: 5px;
}
#left .sub_menu ul {
    margin:0;
    padding:0;
    list-style-type:none;
}
#left .sub_menu ul li {
    margin:0;
    padding:0;
}
#left .sub_menu a,
#left .sub_menu strong {
    display:block;
    padding:22px 10px;
    text-align:center;
    border-bottom:1px dashed #827e77;
    font-size:18px;
    line-height:22px;
    text-decoration:none;
    color:#514d4b;
    text-transform:uppercase;
}
#left .sub_menu a:hover,
#left .sub_menu strong {
    background-color:#ffdd21;
}
#left .sub_menu a.trail,
#left .sub_menu strong.trail {
    background-color:#ffea81;
    font-weight:bold;
}
#left .sub_menu .level_2 a {
    background-color:#fff8e2;
}
#left .sub_menu .level_1 > li > a,
#left .sub_menu .level_1 > li > strong {
    position:relative;
    padding-right:40px;
    padding-left:40px;
}
#left .sub_menu .level_1 > li > a:before,
#left .sub_menu .level_1 > li > strong:before {
    height:100%;
    left:0;
    top:0;
    position:absolute;
    font-size:50px;
    line-height:67px;
    content: "\e601";
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#left .sub_menu .level_1 > li > a.trail:before,
#left .sub_menu .level_1 > li > strong:before {
    content: "\e600";
}
#right .icon_box {
    width:50%;
}
.formbody label,
.formbody legend {
    display:block;
    margin-bottom:4px;
    font-weight:bold;
}
.formbody label span.mandatory,
.formbody legend span.mandatory {
    margin-left:2px;
    color:#f00;
}
.formbody p.error {
    margin-bottom:4px;
    font-weight:bold;
    color:#f00;
}
.formbody fieldset {
    border: none;
    margin: 0;
    padding: 0;
}
.formbody .widget {
    margin-bottom:10px;
}
.formbody .text,
.formbody .textarea,
.formbody .captcha {
    width:100%;
    height:32px;
    margin:0;
    padding:0 8px;
    background-color:#fff8e3;
    border:1px solid #bcb7a7;
    border-radius:8px;
    font:18px/32px Titillium Web,Arial,sans-serif;
    color:#278aad;
    -webkit-appearance: none;
    -webkit-transition: border-color .2s;
    -moz-transition: border-color .2s;
    -o-transition: border-color .2s;
    transition: border-color .2s;
}
.formbody .text.error,
.formbody .textarea.error,
.formbody .captcha.error {
    background: #fff1f1 !important;
    border-color: #d80000 !important;
}
.formbody .text:focus,
.formbody .textarea:focus,
.formbody .captcha:focus {
    border-color:#7d7d7d;
}
.formbody .textarea {
    height:140px;
    padding-top:8px;
    line-height:22px;
    resize: vertical;
}
.formbody .checkbox_container,
.formbody .radio_container {
    margin:0;
    padding:0;
    border:0;
}
.formbody .checkbox_container label,
.formbody .radio_container label {
    display:inline-block;
    font-weight:normal;
    cursor: pointer;
}
.formbody .checkbox_container > span,
.formbody .radio_container > span {
    display:block;
}
.formbody select.select {
    display: block;
    font-size: 1rem;
    color: inherit;
    font-size: 1rem;
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #bcb7a7;
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #fff8e3;
    	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    	background-repeat: no-repeat;
    	background-position: right .7em top 50%;
    	background-size: .65em auto;
}
.formbody select.select::-ms-expand {
    display: none;
}
.formbody select.select:hover {
    border-color: #7d7d7d;
}
.formbody select.select:focus {
    border-color: #7d7d7d;
    	color: #278aad;
    	outline: none;
}
.formbody select.select option {
    font-weight:normal;
}
.formbody .captcha {
    width:75px;
    margin-right:8px;
    text-align:center;
}
.formbody .captcha_text {
    font-weight:bold;
}
.submit_container .submit,
.formbody .submit {
    width:100%;
    margin:0;
    padding:8px 24px;
    background-color:#514d4b;
    border:0;
    border-radius:0;
    color:#fff8e3;
    font-size: 1rem;
    cursor: pointer;
    -webkit-appearance: none;
}
.submit_container .submit:active,
.formbody .submit:active {
    background-color:#242221;
}
.ce_form .ajaxconfirm {
    font-weight:bold;
}
.mod_tvlyss_ribbon {
    width:300px;
    height:50px;
    left:-90px;
    top:40px;
    position:fixed;
    text-align:center;
    background-color:#000;
    font-size:.8rem;
    line-height:50px;
    font-weight:normal;
    color:#fff;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 9999;
}
.mod_tvlyss_ribbon a {
    text-decoration:none;
    color:inherit;
}
.mod_tvlyss_ribbon a:hover {
    text-decoration: underline;
}
.mod_tvlyss_ribbon .desktop {
    display:block;
}
.mod_tvlyss_ribbon .mobile {
    display:none;
}
.ce_player {
    margin-bottom: 40px;
}

.ce_tvlyss_juicer_feed {
    .juicer-feed.white li.feed-item, .juicer-feed.user li.feed-item, .juicer-feed.juicer-widget li.feed-item, .juicer-feed.colors li.feed-item, .juicer-feed.gray li.feed-item, .juicer-feed.modern li.feed-item, .juicer-feed.polaroid li.feed-item {
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: background-color .2s;

        &:hover {
            background-color: #fff;
        }
    }

    .juicer-feed.modern {
        display: flex;
        flex-direction: column;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    .juicer-feed.modern .referral {
        order: 1;

        a {
            color: #ef6395;
        }
    }

    .juicer-feed.modern .j-message {
        color: inherit;
        line-height: inherit;

        br + br {
            display: none;
        }
    }

    .juicer-feed.modern .j-poster {
        padding: 10px;
        height: auto;
        display: block;
        text-align: center;
        background-color: #514d4b;
        color: #fff8e3;
        line-height: 1;
        font-size: 14px;
    }

    .juicer-feed.modern .j-poster h3,
    .juicer-feed.modern .j-poster img {
        display: none;
    }

    .juicer-feed.modern .j-poster .j-date {
        margin-top: 0;
        float: none;
        font-size: inherit;
        color: #fff;
    }

    .juicer-feed.modern .j-meta {
        display: none;
    }

    .juicer-feed.modern .j-text {
        font-size: 1rem;
        padding: 10px;
        border-left: 1px dashed #827e77;
        border-right: 1px dashed #827e77;
        border-bottom: 1px dashed #827e77;
    }

    .juicer-feed.modern .j-text .j-message p {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .juicer-feed.modern .j-image {
        display: block !important;
        border-left: 1px dashed #827e77;
        border-right: 1px dashed #827e77;
    }

    a[href^=http] {
        padding-left: 0 !important;

        &::before {
            display: none !important;
        }
    }
}

// Shop
.mod_iso_messages {
    .iso_confirm {
        padding: 0.5em;
        background: #ffdd23;

        &::before {
            margin-right: .5em;
            @extend [class^="icon-"];
            @extend .icon-checkmark;
        }
    }
}

.mod_iso_productlist {
    .product-list-group:not(:last-child) {
        margin-bottom: 5rem;
    }

    .product {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px dashed #d9cec8;
    }

    .product-wrapper {
        display: flex;
    }

    .product-gallery {
        min-width: 150px;
        width: 150px;

        .image_container {
            padding: 0.5em;
            background: #fff;
            border: 1px dashed #827e77;

            img {
                display: block;
            }
        }
    }

    .product-content {
        margin-left: 1rem;
        flex-grow: 1;
    }

    .product-header {
        display: flex;
        align-items: center;

        h3 {
            margin: 0;
        }

        .sku {
            margin-left: .5em;
            color: #a39b96;

            &::before {
                content: '(';
            }

            &::after {
                content: ')';
            }
        }

        .offer {
            margin-left: auto;
            padding: 0.3em 0.7em;
            background: #ffdd23;
            font-weight: bold;
        }
    }

    .product-body {
        fieldset {
            display: flex;
        }

        label,
        legend {
            font-weight: normal;

            .mandatory {
                display: none;
            }
        }

        .radio_container > span {
            cursor: pointer;

            > * {
                cursor: pointer;
            }

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .textarea {
            height: 2.2em;
        }
    }

    .product-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .quantity_container {
            display: flex;
            align-items: center;
            margin-right: 1em;

            label {
                margin-right: .5em;
                font-weight: normal;
            }

            .text {
                width: 100px;
                text-align: center;
            }
        }

        .submit {
            width: auto;
        }
    }
}

.mod_iso_cart {
    table {
        border-bottom: 1px dashed #827e77;

        tbody tr:hover th,
        tbody tr:hover td {
            background-color: transparent !important;
        }

        td {
            padding: .5em;
        }

        td.image {
            min-width: 150px;
            width: 150px;

            .image_container {
                padding: 0.5em;
                background: #fff;
                border: 1px dashed #827e77;

                img {
                    display: block;
                }
            }
        }

        td.name {
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
            }

            li {
                padding: 0;
                margin: 0;
            }
        }

        td.quantity {
            width: 100px;

            input {
                text-align: center;
            }
        }

        td.actions {
            text-align: center;

            a {
                display: inline-block;

                &.edit {
                    display: none;
                }
            }
        }
    }

    .submit_container {
        display: flex;

        .button_update {
            background-color: transparent;
            border: 1px solid #514d4b;
            color: #514d4b;
        }

        > *:not(:last-child) {
            margin-right: 1em;
        }
    }
}

.mod_iso_checkout {
    .formbody {
        margin-bottom: .5em;
    }

    .address_new {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        > div {
            padding: 0 15px;
            width: 50%;

            &.widget-checkbox {
                width: 100%;
                margin-top: 10px;

                fieldset > legend {
                    display: none;
                }
            }
        }
    }

    .orderinfo {
        h3 {
            margin-bottom: 0;
        }

        .info_container {
            margin-bottom: 1rem;
        }
    }

    .orderproducts {
        table {
            border-bottom: 1px dashed #827e77;

            tbody tr:hover th,
            tbody tr:hover td {
                background-color: transparent !important;
            }

            td {
                padding: .5em;
            }

            td.image {
                min-width: 150px;
                width: 150px;

                .image_container {
                    padding: 0.5em;
                    background: #fff;
                    border: 1px dashed #827e77;

                    img {
                        display: block;
                    }
                }
            }

            td.name {
                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                }

                li {
                    padding: 0;
                    margin: 0;
                }
            }

            td.quantity {
                width: 100px;

                input {
                    text-align: center;
                }
            }

            td.actions {
                text-align: center;

                a {
                    display: inline-block;

                    &.edit {
                        display: none;
                    }
                }
            }
        }
    }

    .submit_container {
        display: flex;

        .previous {
            background-color: transparent;
            border: 1px solid #514d4b;
            color: #514d4b;
        }

        > *:not(:last-child) {
            margin-right: 1em;
        }
    }
}
.shop-cart-link {
    display: none;
}
